import React from 'react';
import {
  List,
  Create,
  Datagrid,
  DateField,
  DisabledInput,
  Edit,
  EditButton,
  EmailField,
  Filter,
  NumberField,
  required,
  SimpleForm,
  TextInput,
  TextField,
} from 'react-admin';

export const ModelList = (props) => (
  <List {...props} title="Modelos">
    <Datagrid>
      <TextField source="name" label="Modelo" />
      <NumberField source="unitPrice" label="Preço unitário" options={{ style: 'currency', currency: 'BRL' }} />
      <TextField source="brand" label="Fabricante" />
      <TextField source="kind" label="Tipo" />
      <EditButton />
    </Datagrid>
  </List>
)

export const ModelCreate = (props) => (
  <Create {...props} title="Novo modelo de equipamento">
    <SimpleForm>
      <TextInput source="name" label="Modelo" validate={[required()]} />
      <TextInput source="kind" label="Tipo de Equipamento" validate={[required()]} />
      <TextInput source="serialMask" label="Regex de Validação do Serial" />
      <TextInput source="brand" label="Fabricante" />
      <TextInput source="weight" label="Peso" />
      <TextInput source="unitPrice" label="Preço unitário" />
    </SimpleForm>
  </Create>
)

export const ModelEdit = (props) => (
  <Edit {...props} title="Edição de modelo de equipamento">
    <SimpleForm>
      <TextInput source="name" label="Modelo" validate={[required()]} />
      <TextInput source="kind" label="Tipo de Equipamento" validate={[required()]} />
      <TextInput source="serialMask" label="Regex de Validação do Serial" />
      <TextInput source="brand" label="Fabricante" />
      <TextInput source="weight" label="Peso" />
      <TextInput source="unitPrice" label="Preço unitário" />
    </SimpleForm>
  </Edit>
)
