import React from 'react';
import {
  List,
  Create,
  Datagrid,
  DateField,
  DisabledInput,
  Edit,
  EditButton,
  EmailField,
  Filter,
  required,
  SimpleForm,
  TextInput,
  TextField,
} from 'react-admin';

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Busca" source={['name','email']} alwaysOn />
  </Filter>
);

export const UsersList = (props) => (
    <List {...props} title="Usuários" filters={<UserFilter />}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <EmailField source="email" label="E-mail" />
            <DateField source="createdAt" locales="pt-BR" showTime label="Criado em"/>
            <DateField source="updatedAt" locales="pt-BR" showTime label="Alterado em"/>
            <EditButton />
        </Datagrid>
    </List>
)

const validatePassword = (value, allValues) => {

  if (value !== allValues.password) {
    return 'As senhas digitadas são diferentes';
  }
  return undefined;

}

export const UserCreate = (props) => (
  <Create {...props} title="Novo usuário">
    <SimpleForm>
      <TextInput label="Nome" source="name" validate={[required()]} />
      <TextInput label="E-mail" source="email"  validate={[required()]} />
      <TextInput label="Senha" source="password" type="password"  validate={[required()]} />
      <TextInput label="Repita a senha" source="password_confirmation" type="password" validate={validatePassword} />
    </SimpleForm>
  </Create>
);

export const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id" />
      <TextInput label="Nome" source="name" validate={[required()]} />
      <TextInput label="Email" source="email" validate={[required()]} />
      <TextInput label="Senha" source="password" type="password" />
    </SimpleForm>
  </Edit>
);
