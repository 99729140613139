// in src/MyLoginPage.js
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import {
  TextField,
  Button,
} from '@material-ui/core';
import styled from 'styled-components';
import { GoogleLogin } from 'react-google-login';

const LoginPage = styled.div`
  height: 1px;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background: url(https://source.unsplash.com/random/1600x900);
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
`

const LoginCard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 440px;
  width: calc(100% - 40px);
  padding: 44px;
  margin-bottom: 28px;
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 15px 35px 0px;
`

const Warning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #e81123;
  margin: 15px 0 0 0;
`

class MyLoginPage extends Component {

    constructor(props) {
      super(props);

      this.state = {
        googleAuth: false,
        username: null,
        password: null,
        warning: '',
      };
    }

    handleChange = name => event => {
      this.setState({
        [name]: event.target.value,
      });
    };

    submit = (e) => {
        const { username, password, googleAuth } = this.state;

        e.preventDefault();
        // gather your data/credentials here
        const credentials = { username , password, googleAuth: googleAuth };

        // Dispatch the userLogin action (injected by connect)
        this.props.userLogin(credentials);
    }

    responseGoogle = (response) => {
      if (response.profileObj && response.profileObj.email) {
        this.props.userLogin({
          username: response.profileObj.email,
          googleAuth: true
        });
      }
    }

    render() {
      const { warning } = this.props;
      return (
          <LoginPage>
            <form onSubmit={this.submit} ref="LoginForm">
              <LoginCard>
                <GoogleLogin
                  clientId={"537100521351-8bpv6esknuhj6sjpm55u6p8vu40uarsb.apps.googleusercontent.com"}
                  buttonText="Entrar com Google"
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogle}
                />
                <Warning>{warning}</Warning>
                <TextField
                  id="login"
                  label="E-Mail"
                  style={{marginTop: '20px'}}
                  onChange={this.handleChange('username')}
                  />
                <TextField
                  id="password"
                  label="Senha"
                  type="Password"
                  style={{marginTop: '20px'}}
                  onChange={this.handleChange('password')}
                  />
                <Button color="primary" variant="contained"
                  style={{marginTop: '40px'}}
                  type="Submit"
                >
                  Entrar
                </Button>
              </LoginCard>
            </form>
          </LoginPage>
      );
    }
};

const mapStateToProps = state => {
  if (state.admin.notifications && state.admin.notifications.length) {
    return {
      warning: 'Usuário/senha incorretos.'
    }
  }
  return {};
}

export default connect(mapStateToProps, { userLogin })(MyLoginPage);
