import React from 'react';
import {
  Datagrid,
  DisabledInput,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin';

const condition = [
  { id: 'good', name: 'Good' },
  { id: 'nogood', name: 'Nogood' }
];

const location = [
  { id: 'stock', name: 'Em estoque'},
  { id: 'packinglist', name: 'Romaneio' },
  { id: 'customer', name: 'Cliente' },
  { id: 'inspection', name: 'Inspeção' },
  { id: 'reverse', name: 'Reversa' }
];

const InventoryFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Procurar" source="serialNumber" alwaysOn /> */}
    <SelectInput label="Condição" source="condition" choices={condition} />
    <SelectInput label="Localização" source="location" choices={location} />
    <ReferenceInput sort={{ field: 'name', order: 'ASC' }} label="Modelo" source="modelId" reference="model">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const InventoryList = (props) => (
  <List {...props} title="Estoque" filters={<InventoryFilter />}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Modelo" source="modelId" reference="model">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="serialNumber" label="Número de Série" />
      <TextField source="numberOfProperty" label="Patrimonio"  />
      <TextField source="condition" label="Condição" />
      <TextField source="location" label="Localização" />
      <EditButton />
    </Datagrid>
  </List>
);

const InventoryEdit = (props) => (
  <Edit title="Edição de Estoque" {...props} >
    <SimpleForm>
      <DisabledInput source="id" />
      <ReferenceField label="Modelo" source="modelId" reference="model">
        <TextField source="name" />
      </ReferenceField>
      <TextInput label="Número de Série" source="serialNumber"/>
      <TextInput label="Patrimônio" source="numberOfProperty"/>
      <SelectInput label="Localização" source="location" choices={location} />
      <SelectInput label="Condição" source="condition" choices={condition} />
    </SimpleForm>
  </Edit>
)
 export {
  InventoryList,
  InventoryEdit
 };
