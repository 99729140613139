import React from 'react';
import {
  List,
  Create,
  Datagrid,
  DateField,
  DisabledInput,
  Edit,
  EditButton,
  EmailField,
  Filter,
  FormTab,
  required,
  SimpleForm,
  TabbedForm,
  TextInput,
  TextField,
} from 'react-admin';

export const ContractorList = (props) => (
  <List {...props} title="Técnicos">
      <Datagrid>
          <TextField source="id" />
          <TextField source="name" label="Nome" />
          <TextField source="document" label="CPF" />
          <TextField source="phoneNumber" label="Telefone" />
          <EditButton />
      </Datagrid>
  </List>
)

export const ContractorCreate = (props) => (
  <Create {...props} title="Novo Técnico">
    <TabbedForm>
      <FormTab label="Informações do técnico">
        <TextInput source="name" label="Nome" validate={[required()]} />
        <TextInput source="document" label="CNPJ/CPF" validate={[required()]} />
        <TextInput source="phoneNumber" label="Número do Telefone" validate={[required()]} />
      </FormTab>
      <FormTab label="Acesso ao sisetma">
        <TextInput source="user.email" label="E-mail" type="email" />
        <TextInput source="user.password" label="Senha" type="password" />
      </FormTab>
    </TabbedForm>
  </Create>
)

export const ContractorEdit = (props) => (
  <Edit {...props} title="Edição de Técnico">
    <SimpleForm>
        <TextInput source="name" label="Nome" validate={[required()]} />
        <TextInput source="document" label="CNPJ/CPF" validate={[required()]} />
        <TextInput source="phoneNumber" label="Número do Telefone" validate={[required()]} />
    </SimpleForm>
  </Edit>
)
