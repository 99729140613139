import React from 'react';
import {
  Create,
  DateField,
  Datagrid,
  DateTimeInput,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import FetchButton from './fetchButton';
import { FormDataConsumer } from 'react-admin';

export const OSList = (props) => (
  <List {...props} title="Usuários">
    <Datagrid>
      <TextField source="id" />
      <DateField source="dateOfOpen" label="Abertura" locales="pt-BR" showTime />
      <DateField source="dueDate" label="Vencimento" locales="pt-BR" showTime />
      <TextField source="status" label="Status" />
      <ReferenceField label="Serviço" source="serviceId" reference="service" linkType={false}>
        <TextField source="region" />
      </ReferenceField>
      <EditButton />
      </Datagrid>
  </List>
)

export const OSCreate = props => (
  <Create title="Importar Ordem de Serviço" {...props}>
    <SimpleForm>
      <TextInput source="externalId" label="Número da OS" />
      <ReferenceInput label="Contratante" source="id" reference="requester">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData }) => formData && (
          <div >
            <FetchButton data={formData}/>
            { formData.id ?
              <ReferenceInput
                label="Serviço"
                source="serviceId"
                reference="service"
                filter={{ "requesterId": formData.id ? formData.id : 0 }}
                sort={{field: 'type', order:'asc'}}
                allowEmpty
              >
                <SelectInput optionText="type" />
              </ReferenceInput>
              : null}
          </div>
        )}
      </FormDataConsumer>
      <DateTimeInput label="Abertura" source="dateOfOpen" />
      <DateTimeInput label="Vencimento" source="dueDate" />

      {/* <LongTextInput label="Descrição" source="description" /> */}
      <TextInput source="customer.name" label="Cliente" />
      <TextInput source="customer.externalId" label="Identificador" />
      <TextInput source="customer.address" label="Endereço" />
      <TextInput source="customer.addressNumber" label="Número" />
      <TextInput source="customer.complement" label="Complemento" />
      <TextInput source="customer.city" label="Cidade" />
      <TextInput source="customer.state" label="Estado" />
      <TextInput source="customer.neightborhood" label="Bairro" />
      <TextInput source="customer.zipCode" label="CEP" />
      <TextInput source="customer.region" label="Região" />
    </SimpleForm>
  </Create>
);
