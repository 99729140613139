import React from 'react';
import { Admin, Resource } from 'react-admin';

import authProvider from './authProvider';
import dataProvider from './dataProvider';
import myLoginPage from './myLoginPage';

import {
  Assignment,
  People,
  Work,
  Pages,
} from '@material-ui/icons';

import { UsersList, UserCreate, UserEdit } from './resources/user';
import { OSList, OSCreate } from './resources/orderofservice';
import { InventoryList, InventoryEdit }  from './resources/inventory/index';
import InventoryCreate  from './resources/inventory/create';
import { ModelList, ModelCreate, ModelEdit } from './resources/model';
import { ContractorList, ContractorCreate, ContractorEdit } from './resources/contractor';

import ptBR from 'ra-language-portuguese'

const messages = {
  pt: ptBR,
}

const i18nProvider = locale => messages[locale];

const App = () => (
  <Admin
    locale="pt"
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={myLoginPage}
  >
    <Resource
      name="user"
      icon={People}
      list={UsersList}
      create={UserCreate}
      edit={UserEdit}
      options={{ label: "Usuários" }}
    />
    <Resource
      name="contractor"
      icon={Work}
      options={{ label: "Técnicos" }}
      list={ContractorList}
      create={ContractorCreate}
      edit={ContractorEdit}
    />
    <Resource
      name="orderofservice"
      icon={Assignment}
      list={OSList}
      create={OSCreate}
      options={{ label: "Ordem de Servico" }}
    />
    <Resource
      name="inventoryitem"
      list={InventoryList}
      create={InventoryCreate}
      edit={InventoryEdit}
      options={{ label: "Estoque" }}
    />
    <Resource
      name="packinglist"
      options={{ label: "Romaneio" }}
    />
    <Resource
      name="service"
    />
    <Resource
      name="requester"
    />
    <Resource
      name="model"
      icon={Pages}
      list={ModelList}
      create={ModelCreate}
      edit={ModelEdit}
      options={{ label: "Modelos" }}
    />
  </Admin>
);

export default App;
