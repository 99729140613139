import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  fetchUtils,
} from 'react-admin';
import { stringify } from 'query-string';
import config from './config/config.json'

const env = process.env.NODE_ENV || 'development';

const API_URL = `${config[env].host_api}/v1`;

/**
* @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
* @param {String} resource Name of the resource to fetch, e.g. 'posts'
* @param {Object} params The Data Provider request params, depending on the type
* @returns {Object} { url, options } The HTTP request parameters
*/
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  let options = {
    headers: new Headers({
      'Accept': 'application/json'
    }),
  };
  switch (type) {
  case GET_LIST: {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    let query = {
      sort: order === 'DESC' ? `-${field}` : field,
      offset: (page - 1) * perPage,
      limit: page * perPage - 1,
      //range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      // filter: JSON.stringify(params.filter),
    };
    console.log('antes');
    console.log(query);
    console.log(params.filter);
    query = Object.assign(query, params.filter);
    console.log('depois');
    console.log(query);
    return {
      url: `${API_URL}/${resource}?${stringify(query)}`,
      options,
    };
  }
  case GET_ONE:
    return {
      url: `${API_URL}/${resource}/${params.id}`,
      options,
    };
  case GET_MANY: {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return {
      url: `${API_URL}/${resource}?${stringify(query)}`,
      options,
    };
  }
  case GET_MANY_REFERENCE: {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
      filter: JSON.stringify({ ...params.filter, [params.target]: params.id }),
    };
    return {
      url: `${API_URL}/${resource}?${stringify(query)}`,
      options,
    };
  }
  case UPDATE:
    options = Object.assign(options, { method: 'PUT', body: JSON.stringify(params.data)});
    return {
      url: `${API_URL}/${resource}/${params.id}`,
      options,
    };
  case CREATE:
    options = Object.assign(options, { method: 'POST', body: JSON.stringify(params.data) });
    return {
      url: `${API_URL}/${resource}`,
      options
    };
  case DELETE:
    options = Object.assign(options, { method: 'DELETE' });
    return {
      url: `${API_URL}/${resource}/${params.id}`,
      options
    };
  default:
    throw new Error(`Unsupported fetch action type ${type}`);
  }
};

/**
* @param {Object} response HTTP response from fetch()
* @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
* @param {String} resource Name of the resource to fetch, e.g. 'posts'
* @param {Object} params The Data Provider request params, depending on the type
* @returns {Object} Data Provider response
*/
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
  const { headers, json } = response;
  switch (type) {
  case GET_LIST:
  case GET_MANY_REFERENCE:
  case GET_MANY:
    let total = 0;
    if (headers.get('content-range')) {
      total = parseInt(headers.get('content-range').split('/').pop(), 10);
    } else if (headers.get('x-total-count')) {
      total = headers.get('x-total-count');
    } else {
      total = json.length;
    }
    return {
          data: json.map(x => x),
          total: total,
      };
  case CREATE:
    return { data: { ...params.data, id: json.id } };
  default:
    return { data: json };
  }
};

/**
* @param {string} type Request type, e.g GET_LIST
* @param {string} resource Resource name, e.g. "posts"
* @param {Object} payload Request parameters. Depends on the request type
* @returns {Promise} the Promise for response
*/
export default (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  const { url, options } = convertDataProviderRequestToHTTP(type, resource, params);

  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  // console.log('Authorization', `Bearer ${token}`);
  return fetchJson(url, options)
    .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
};
