import React from 'react';
import update from 'react-addons-update';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import styled from 'styled-components';
import {
  CREATE,
  Create,
  fetchEnd,
  fetchStart,
  GET_ONE,
  ReferenceInput,
  showNotification,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import {
  Dialog,
  TextField,
  IconButton,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';

import dataProvider from '../../dataProvider';

const BottomContaier = styled.div`
  top: auto;
  bottom: 0;
  display: flex;
  position: relative;
  background-color: #1769aa;
  color: #fff;
  min-height: 64px;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 0 0 4px 4px;
  margin-top: -4px;
`

const BottomContaierLabel = styled.div`
  font-weigth: 600;
  font-size: 24px
`

const BottomContaierCounter = styled.div`
  font-weigth: 600;
  font-size: 24px
`

class InventoryCreate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      qty: 1,
      sn: [],
      numberOfProperty: [],
      modelId: 0,
      mask: null,
      showErrorDialog: false,
      fetchStarted: false,
      remaining: 0,
      deliveryDate: null,
      packageNumber: 0,
      invoiceNumber: 0
    }
  }

  handleSNChange = (idx, event) => {
    this.setState({
      sn: update(this.state.sn, { [idx]: { $set: event.target.value } })
    })
  }

  handlePropertyChange = (idx, event) => {
    this.setState({
      numberOfProperty: update(this.state.numberOfProperty, { [idx]: { $set: event.target.value } })
    })
  }

  handleModelChange = async (event, value) => {
    const { fetchStart, fetchEnd } = this.props;
    let modelData;
    fetchStart();
    try {
      modelData = await dataProvider(GET_ONE, 'model', { id: value });
      this.setState({
        modelId: modelData.data.id,
        mask: modelData.data.serialMask
      });
    } catch (e) {
      showNotification(e.message, 'Error')
    } finally {
      fetchEnd();
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  plusButtonClick = () => {
    let { qty } = this.state;
    qty++;

    this.setState({ qty });
  }

  createFields = () => {
    let fields = [];
    const { qty, sn, mask, packageNumber } = this.state;
    console.log(packageNumber);
    let tabIndex = 0;
    for (let i = 0; i < qty; i++) {
      fields.push(
        <React.Fragment key={`fragment-${i}`}>
          <TextField
            label="Número de Série"
            name={`serialNumber[${i}]`}
            onChange={(e) => this.handleSNChange(i, e)}
            key={`serial${i}`}
            autoFocus
            InputProps={{ inputProps: { tabIndex: ++tabIndex } }}
            error={mask && !String(sn[i]).match(mask)}
          />
          <TextField
            label="Patrimonio"
            name={`numberOfProperty[${i}]`}
            onChange={(e) => this.handlePropertyChange(i, e)}
            key={`np${i}`}
            style={{marginLeft: '50px'}}
            InputProps={{ inputProps: { tabIndex: ++tabIndex } }}
          />
          <IconButton
            variant="contained"
            color="primary"
            onClick={this.plusButtonClick}
            key={`plus${i}`}
            tabIndex={++tabIndex}
          >
            <AddCircle />
          </IconButton>
        </React.Fragment>
      );
    }

    return fields;
  };

  handleSubmit = async () => {
    const { fetchStart, fetchEnd, showNotification } = this.props;
    const {
      modelId,
      sn,
      mask,
      packageNumber,
      deliveryDate,
      invoiceNumber
    } = this.state;

    const withError = sn.filter(serial => serial && mask &&!String(serial).match(mask))
    if (withError.length >= 1) {
      this.setState({ showErrorDialog: true })
      return;
    }

    this.setState({ fetchStarted: true });
    for (let i = 0; i < sn.length; i++) {
      fetchStart();
      this.setState({ remaining: (sn.length - i) });
      const numberOfProperty = this.state.numberOfProperty[i];
      const serialNumber = this.state.sn[i];
      console.log('serialNumber', serialNumber);
      const data = {
        serialNumber,
        numberOfProperty,
        modelId,
        packageNumber,
        deliveryDate,
        invoiceNumber
      };
      try {
        await dataProvider(CREATE, 'inventoryitem', { data: { ...data } })
      } catch (e) {
        showNotification(e.message, 'Erro')
      } finally {
        fetchEnd();
      }
    }
    console.log('redirect');
    this.props.history.push('/inventoryitem');
  }

  componentDidMount() {
    for (let x in this.refs) {
      this.refs[x].onkeypress = (e) =>
        this.handleKeyPress(e, this.refs[x]);
    }
  }

  handleKeyPress(e, field) {
    console.log(field);
    // If enter key is pressed, focus next input field.
    if (e.keyCode === 13) {
      e.preventDefault();
      let next = this.refs[field.name].nextSibling;
      if (next && next.tagName === "INPUT") {
        this.refs[field.name].nextSibling.focus();
      }
    }
  }

  scrollToBottom = () => {
    this.bottom.scrollIntoView();
  }

  componentDidMount = () => {
    this.scrollToBottom();
  }

  componentDidUpdate = () => {
    this.scrollToBottom();
  }

  renderErrorDialog = () => {
    const { showErrorDialog } = this.state;
    return (
      <Dialog
        fullWidth
        open={showErrorDialog}
      >
        <DialogTitle>Impossível continuar</DialogTitle>
        <DialogContent>Existem número de série fora do padrão. Verifique os números digitados e tente novamente.</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => this.setState({showErrorDialog: false})}>OK</Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderSavingDialog = () => {
    const { fetchStarted, remaining } = this.state;
    return (
      <Dialog
        fullWidth
        open={fetchStarted}
      >
        <DialogTitle>Gravando Dados</DialogTitle>
        <DialogContent>Gravando equipamentos ao sistema<br/>Faltam {remaining} equipamentos a serem adicionados</DialogContent>
      </Dialog>
    )
  }

  render() {
    const { qty, modelId } = this.state;
    return (
      <div>
        { this.renderErrorDialog() }
        { this.renderSavingDialog() }
        <Create {...this.props} title="Adicionando item(s) ao estoque">
          <SimpleForm submitOnEnter={false}
            form="inventoryBulkCreate"
            handleSubmit={() => this.handleSubmit}
            redirect="list"
            >
            <React.Fragment key="frament-1">
              <ReferenceInput sort={{ field: 'name', order: 'ASC' }} label="Modelo" source="modelId" reference="model" resource="model" onChange={this.handleModelChange}>
                <SelectInput optionText="name" />
              </ReferenceInput>
            </React.Fragment>
            <React.Fragment>
              <TextField source="deliveryDate" label="Data do Recebimento" type="date" InputLabelProps={{ shrink: true }} onChange={this.handleChange('deliveryDate')} />
              <TextInput source="invoiceNumber" label="Nota Fiscal/Romaneio" onChange={this.handleChange('invoiceNumber')} style={{ marginLeft: '15px' }} />
              <TextInput source="packageNumber" label="Número da Caixa" onChange={this.handleChange('packageNumber')} style={{ marginLeft: '15px' }} />
            </React.Fragment>
          { modelId && this.createFields() }
          </SimpleForm>
        </Create>
        <BottomContaier ref={el => { this.bottom = el; }}>
          <BottomContaierLabel>Total a inserir</BottomContaierLabel>
          <BottomContaierCounter>{qty - 1}</BottomContaierCounter>
        </BottomContaier>
      </div>
    )
  }
};

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(undefined, mapDispatchToProps)(
  InventoryCreate
);
