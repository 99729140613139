import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import Button from '@material-ui/core/Button';

class FetchButton extends Component {
  handleClick = async () => {
    console.log('here');
    const { showNotification, data } = this.props;
    const result = await fetch(`http://os.lcsantos.com.br/os/${data.externalId}/stone`)
    const body = JSON.parse(await result.text());
    data.customer = {
        ...body.customer
    };
    data.dateOfOpen = body.dateOfOpen;
    data.dueDate = body.duedate;
    // data.customer = {
    //   ...body,
    //   externalId: body.code,
    // };
    showNotification('handleClick');
  }

  render() {
    return <Button variant="contained" color="secondary" onClick={this.handleClick}>Importar Dados</Button>
  }
}

FetchButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func
};

export default connect(null, {
  showNotification,
})(FetchButton);
